// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Roboto&display=swap);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "* {\n  margin: 0;\n  box-sizing: border-box;\n}\n\ninput,\ntextarea,\noption {\n  color: black;\n}\n\nbody {\n  background-color: var(--tg-theme-bg-color);\n  color: var(--tg-theme-text-color);\n  font-family: \"Roboto\", sans-serif;\n}\n", "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AACA;EACE,SAAS;EACT,sBAAsB;AACxB;;AAEA;;;EAGE,YAAY;AACd;;AAEA;EACE,0CAA0C;EAC1C,iCAAiC;EACjC,iCAAiC;AACnC","sourcesContent":["@import url(\"https://fonts.googleapis.com/css2?family=Roboto&display=swap\");\n* {\n  margin: 0;\n  box-sizing: border-box;\n}\n\ninput,\ntextarea,\noption {\n  color: black;\n}\n\nbody {\n  background-color: var(--tg-theme-bg-color);\n  color: var(--tg-theme-text-color);\n  font-family: \"Roboto\", sans-serif;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
