
// *************************************************************************NEW*********************************************************************

import React, { useEffect, useState } from "react";
import { getOnlyBulkProduct } from "../api/api";
import { useNavigate } from "react-router-dom";

const Info = () => {
  const redirect = useNavigate();
  // eslint-disable-next-line no-restricted-globals
  const path = location.pathname.substring(15);
  const [info, setInfo] = useState();
  const [img, setImg] = useState();
  const [disabled, setDisabled] = useState(false);
  const [counterBulk, setCounterBulk] = useState(null);

  useEffect(() => {
    getOnlyBulkProduct(path).then((res) => {
      setInfo(res);

      setImg(() => {
        const imgArrInf = [];

        if (res.data.attributes.mediaBulk.data === null) {
          return imgArrInf; // Если вложенных файлов нет, возвращаем пустой массив
        }

        imgArrInf.push({
          file:
            "https://strapi.belgi.kg" +
            res.data.attributes.mediaBulk.data[0].attributes.url,
        });

        if (res.data.attributes.mediaBulk2.data !== null) {
          imgArrInf.push({
            file:
              "https://strapi.belgi.kg" +
              res.data.attributes.mediaBulk2.data[0].attributes.url,
          });
        }

        if (res.data.attributes.mediaBulk3.data !== null) {
          imgArrInf.push({
            file:
              "https://strapi.belgi.kg" +
              res.data.attributes.mediaBulk3.data[0].attributes.url,
          });
        }

        if (res.data.attributes.mediaBulk4.data !== null) {
          imgArrInf.push({
            file:
              "https://strapi.belgi.kg" +
              res.data.attributes.mediaBulk4.data[0].attributes.url,
          });
        }

        return imgArrInf;
      });
    });
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          `https://strapi.belgi.kg/api/bulk-buyings/${path}`
        );
        const data = await response.json();
        const counterBulkFromAPI = data?.data?.attributes?.CounterBulk;

        if (counterBulkFromAPI) {
          setCounterBulk(counterBulkFromAPI);
          localStorage.setItem("counterBulk", counterBulkFromAPI.toString());
        } else {
          setCounterBulk(1);
          localStorage.setItem("counterBulk", "1");
        }
      } catch (error) {
        console.error("Ошибка при загрузке данных:", error);
      }
    };

    fetchData();
  }, []);

  const handleClick = async () => {
    if (counterBulk && counterBulk < 10) {
      const updatedCounterBulk = counterBulk + 1;
      redirect("/");
      localStorage.setItem("idBulk", path);
      localStorage.removeItem("role");


      // Обновление значения CounterBulk в локальном хранилище
      localStorage.setItem("counterBulk", updatedCounterBulk.toString());

      // Отправка PUT-запроса для обновления значения CounterBulk на сервере
      try {
        const response = await fetch(
          `https://strapi.belgi.kg/api/bulk-buyings/${path}`,
          {
            method: "PUT",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              data: {
                CounterBulk: updatedCounterBulk,
              },
            }),
          }
        );

        if (!response.ok) {
          console.error(
            "Ошибка при обновлении данных на сервере:",
            response.status
          );
        }
      } catch (error) {
        console.error("Ошибка при отправке запроса:", error);
      }

      // Обновление значения CounterBulk в состоянии компонента
      setCounterBulk(updatedCounterBulk);
    } else if (counterBulk >= 10) {
      setDisabled(true);
    }
  };

  useEffect(() => {
    const clearLocalStorage = () => {
      localStorage.removeItem("counterBulk");
    };

    window.addEventListener("beforeunload", clearLocalStorage);

    return () => {
      window.removeEventListener("beforeunload", clearLocalStorage);
    };
  }, []);

  const done = () => {
    if (localStorage.getItem("idBulk") === path) {
      setDisabled(true);
    } else {
      redirect("/");
      localStorage.setItem("idBulk", path);
      localStorage.removeItem("role");
    }
  };

  return (
    <div>
      <div className="ProductMoreInfo-container">
        <div className="ProductMoreInfo-content">
          <h4> Название </h4>
          <p>{info && info.data.attributes.ProductName}</p>
          <h4> Описание</h4>
          <p>{info && info.data.attributes.ProductDescription}</p> <br />
          <h4> Страна</h4>
          <p>{info && info.data.attributes.country}</p> <br />
          <h4>Фото и T/З: </h4>
          <div className="ProductMoreInfo-photo-files-block">
            {img &&
              img.map((data) => {
                return (
                  <div className="ProductMoreInfo-photo-files-content">
                    {data.file.endsWith(".pdf") ? (
                      <a
                        href={data.file}
                        key={Math.random()}
                        target="_blank"
                        rel="noreferrer noopener"
                      >
                        Документ PDF
                      </a>
                    ) : (
                      <a
                        href={data.file}
                        key={Math.random()}
                        target="_blank"
                        rel="noreferrer noopener"
                      >
                        <img
                          style={{
                            width: "120px",
                            height: "120px",
                            objectFit: "contain",
                          }}
                          src={data.file}
                          alt=""
                        />
                      </a>
                    )}
                  </div>
                );
              })}
          </div>
          <button
            disabled={disabled}
            className="ProductMoreInfo-btn"
            onClick={handleClick}
          >
            {counterBulk === 10 ? "Заказ уже  выбран" : "Принять"}
          </button>
        </div>
      </div>
    </div>
  );
};

export default Info;

















