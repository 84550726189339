import React, { useState, useEffect } from "react";

function TestBtn() {
  const [counter, setCounter] = useState(0);
  const [isActive, setIsActive] = useState(true);

  useEffect(() => {
    fetchProductData(); // Получаем данные о продукте при загрузке компонента
  }, []);

  const fetchProductData = () => {
    fetch("https://strapi.belgi.kg/api/bulk-buyings/203")
      .then((response) => response.json())
      .then((data) => {
        const counterValue = data.data.Counter || 0; // Получаем текущее значение счетчика из данных о продукте
        setCounter(counterValue);

        if (counterValue === 10) {
          setIsActive(false); // Делаем кнопку неактивной, если счетчик достиг 10
        } else {
          setIsActive(true); // Делаем кнопку активной в противном случае
        }
      })
      .catch((error) => {
        console.error("Ошибка при получении данных о продукте:", error);
      });
  };

  const onClicked = () => {
    const newCounterValue = counter + 1; // Прибавляем 1 к текущему значению счетчика

    fetch("https://strapi.belgi.kg/api/bulk-buyings/203", {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ data: { Counter: newCounterValue } }),
    })
      .then((response) => response.json())
      .then((data) => {
        setCounter(newCounterValue);

        if (newCounterValue === 10) {
          setIsActive(false); // Делаем кнопку неактивной, если счетчик достиг 10 после обновления
        }
      })
      .catch((error) => {
        console.error("Ошибка при отправке PUT запроса:", error);
      });
  };

  return (
    <>
      <button onClick={onClicked} disabled={!isActive}>
        Принять
      </button>
    </>
  );
}

export default TestBtn;
