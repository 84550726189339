// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".style_myTradesContainer__DtwwG {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  height: 100vh;\n}\n\n.style_myTradesContent__lFCTM {\n  width: 330px;\n  margin: auto;\n  max-height: 320px;\n  padding: 1.25rem;\n  display: flex;\n  flex-direction: column;\n  justify-content: space-between;\n  box-shadow: 0px 0px 19px 2px rgba(34, 60, 80, 0.21);\n  border-radius: 0.75rem;\n}\n.style_myTradesContent__btn__5Se-m {\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n  gap: 1.25rem;\n  margin-top: 20px;\n}\n\n.style_myTradesDescription__i6\\+aI {\n  padding: 10px 0px;\n  margin-bottom: 20px;\n}\n\n.style_btn__HZytH {\n  display: flex;\n  align-items: center;\n  gap: 15px;\n  justify-content: space-between;\n}", "",{"version":3,"sources":["webpack://./src/components/AuthPage/style.module.sass"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,aAAA;AACF;;AACA;EACE,YAAA;EACA,YAAA;EACA,iBAAA;EACA,gBAAA;EACA,aAAA;EACA,sBAAA;EACA,8BAAA;EACA,mDAAA;EACA,sBAAA;AAEF;AADE;EACE,aAAA;EACA,mBAAA;EACA,8BAAA;EACA,YAAA;EACA,gBAAA;AAGJ;;AADA;EACE,iBAAA;EACA,mBAAA;AAIF;;AAFA;EACE,aAAA;EACA,mBAAA;EACA,SAAA;EACA,8BAAA;AAKF","sourcesContent":[".myTradesContainer\r\n  display: flex\r\n  justify-content: center\r\n  align-items: center\r\n  height: 100vh\r\n\r\n.myTradesContent\r\n  width: 330px\r\n  margin: auto\r\n  max-height: 320px\r\n  padding: 1.25rem\r\n  display: flex\r\n  flex-direction: column\r\n  justify-content: space-between\r\n  box-shadow: 0px 0px 19px 2px rgba(34, 60, 80, 0.21)\r\n  border-radius: .75rem\r\n  &__btn\r\n    display: flex\r\n    align-items: center\r\n    justify-content: space-between\r\n    gap: 1.25rem\r\n    margin-top: 20px\r\n\r\n.myTradesDescription\r\n  padding: 10px 0px\r\n  margin-bottom: 20px\r\n\r\n.btn\r\n  display: flex\r\n  align-items: center\r\n  gap: 15px\r\n  justify-content: space-between\r\n\r\n\r\n\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"myTradesContainer": "style_myTradesContainer__DtwwG",
	"myTradesContent": "style_myTradesContent__lFCTM",
	"myTradesContent__btn": "style_myTradesContent__btn__5Se-m",
	"myTradesDescription": "style_myTradesDescription__i6+aI",
	"btn": "style_btn__HZytH"
};
export default ___CSS_LOADER_EXPORT___;
