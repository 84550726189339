import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { getOnlyBulkProduct } from "../api/api";

function InfoDeals() {
  const navigate = useNavigate();
  const [info, setInfo] = useState(null);
  const [img, setImg] = useState([]);

  // eslint-disable-next-line no-restricted-globals
  const path = location.pathname.substring(12);

  useEffect(() => {
    getOnlyBulkProduct(path).then((res) => {
      setInfo(res.data);

      setImg(() => {
        const imgArr = [];

        if (res.data[0].attributes.mediaBulk.data === null) {
          return imgArr; // Если вложенных файлов нет, возвращаем пустой массив
        }

        imgArr.push({
          file:
            "https://strapi.belgi.kg" +
            res.data[0].attributes.mediaBulk.data[0].attributes.url,
        });

        if (res.data[0].attributes.mediaBulk2.data !== null) {
          imgArr.push({
            file:
              "https://strapi.belgi.kg" +
              res.data[0].attributes.mediaBulk2.data[0].attributes.url,
          });
        }

        if (res.data[0].attributes.mediaBulk3.data !== null) {
          imgArr.push({
            file:
              "https://strapi.belgi.kg" +
              res.data[0].attributes.mediaBulk3.data[0].attributes.url,
          });
        }

        if (res.data[0].attributes.mediaBulk4.data !== null) {
          imgArr.push({
            file:
              "https://strapi.belgi.kg" +
              res.data[0].attributes.mediaBulk4.data[0].attributes.url,
          });
        }

        return imgArr;
      });
    });
  }, []);
  console.log(img);
  return (
    <>
      {info && (
        <div className="BulkMoreInfo-container">
          <div className="BulkMoreInfo-content">
            <div className="BulkMoreInfo-title">
              <h4>Название</h4>
              <p>{info[0].attributes.ProductName}</p>
            </div>
            <div>
              <h4>Описание заказа</h4>
              <p className="BulkMoreInfo-desc">
                {info[0].attributes.ProductDescription}
              </p>
            </div>
            <div>
              <h4>Страна</h4>
              <p className="BulkMoreInfo-desc">{info[0].attributes.country}</p>
            </div>
            <div className="ProductMoreInfo-title">
              <h4>Контакный номер телефона</h4>
              <p>{info[0].attributes.phoneNumber}</p>
            </div>

            <div className="ProductMoreInfo-title">
              <h4 className="photo-tz">Фото и Т/З</h4>
              <div className="ProductMoreInfo-photo-files-block">
                {img.map((data) => (
                  <div
                    className="ProductMoreInfo-photo-files-content"
                    key={data.file}
                  >
                    {data.file.endsWith(".pdf") ? (
                      <a
                        href={data.file}
                        target="_blank"
                        rel="noreferrer noopener"
                      >
                        Документ PDF
                      </a>
                    ) : (
                      <a
                        href={data.file}
                        target="_blank"
                        rel="noreferrer noopener"
                      >
                        <img
                          style={{
                            width: "120px",
                            height: "120px",
                            objectFit: "contain",
                          }}
                          src={data.file}
                          alt=""
                        />
                      </a>
                    )}
                  </div>
                ))}
              </div>
            </div>

            <button
              className="BulkMoreInfo-btn"
              onClick={() => navigate("/mydeals")}
            >
              Назад
            </button>
          </div>
        </div>
      )}
    </>
  );
}

export default InfoDeals;

