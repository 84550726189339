import { Button, QRCode, Space, Input } from "antd";
import styles from "./style.module.sass";
import { Link } from "react-router-dom";
import { useState, useEffect } from "react";
import axios from "axios";

const BusinesCard = () => {
  const [text, setText] = useState("");
  const [visitCardLink, setVisitCardLink] = useState("");

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `https://strapi.belgi.kg/api/users/${localStorage.getItem("id")}`
        );
        const data = response.data;
        // Здесь вы можете обработать полученные данные и установить их в состояние
        setText(data.linkToVisit);
        setVisitCardLink(data.linkToVisit);
        // console.log("data", data.linkToVisit);
      } catch (error) {
        alert("Произошло ошибка при загрузке данных, обновите страницу");
        console.error("Ошибка при получении данных:", error);
      }
    };
    fetchData();
  }, []);

  return (
    <div className={styles.bc}>
      <div className={styles.bc__btn}>
        <Button
          type="primary"
          htmlType="submit"
          style={{ margin: "auto" }}
          className="btn-mytrades-exit"
        >
          <a href={visitCardLink} target="_blank" rel="noopener noreferrer">Визитка</a>
        </Button>
      </div>
      <div className={styles.bs__info}>
        <Space direction="vertical" align="center">
          <QRCode value={text || "-"} />
        </Space>
      </div>

      <Button
        type="primary"
        style={{ margin: "auto" }}
        className="btn-mytrades-exit"
      >
        <Link to="/authpage">Назад</Link>
      </Button>
    </div>
  );
};

export default BusinesCard;
