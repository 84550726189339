// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".style_bc__EvieL {\n  display: flex;\n  align-items: center;\n  flex-direction: column;\n  background-color: #ffffff;\n  gap: 1.875rem;\n  width: 20rem;\n  height: 100%;\n  box-shadow: 0px 0px 19px 2px rgba(34, 60, 80, 0.21);\n  padding: 1.5625rem;\n  margin: auto;\n  border-radius: 0.75rem;\n}\n.style_bc__btn__6W9cS {\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n  gap: 1.25rem;\n  width: 100%;\n}\n\n.style_line__c8zDu {\n  border: none;\n}", "",{"version":3,"sources":["webpack://./src/components/BusinesCard/style.module.sass"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,mBAAA;EACA,sBAAA;EACA,yBAAA;EACA,aAAA;EACA,YAAA;EACA,YAAA;EACA,mDAAA;EACA,kBAAA;EACA,YAAA;EACA,sBAAA;AACF;AAAE;EACE,aAAA;EACA,mBAAA;EACA,8BAAA;EACA,YAAA;EACA,WAAA;AAEJ;;AAAA;EACE,YAAA;AAGF","sourcesContent":[".bc\r\n  display: flex\r\n  align-items: center\r\n  flex-direction: column\r\n  background-color: #ffffff\r\n  gap: 1.875rem\r\n  width: 20rem\r\n  height: 100%\r\n  box-shadow: 0px 0px 19px 2px rgba(34, 60, 80, 0.21)\r\n  padding: 1.5625rem\r\n  margin: auto\r\n  border-radius: .75rem\r\n  &__btn\r\n    display: flex\r\n    align-items: center\r\n    justify-content: space-between\r\n    gap: 1.25rem\r\n    width: 100%\r\n\r\n.line\r\n  border: none\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"bc": "style_bc__EvieL",
	"bc__btn": "style_bc__btn__6W9cS",
	"line": "style_line__c8zDu"
};
export default ___CSS_LOADER_EXPORT___;
