

import { useNavigate } from "react-router-dom";
import styles from "./style.module.sass";
import { Button } from "antd";
import { useEffect, useState } from "react";
import {
  AddBulkProductToProviders,
  AddProductionProductToProviders,
} from "../api/api";
import SubscribeNotification from "../SubscribeNotification/SubscribeNotification";

function AuthPage() {
  const navigate = useNavigate();
  const [provider, setProvider] = useState({
    Provider: "",
  });
  const [showNotification, setShowNotification] = useState(false);
  const [subscriptionEndDate, setSubscriptionEndDate] = useState(null);

  const handleClickExit = () => {
    navigate("/");
    localStorage.clear();
  };

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const response = await fetch("https://strapi.belgi.kg/api/users");

        if (!response.ok) {
          throw new Error("Ошибка при получении данных");
        }

        const data = await response.json();

        if (data.length > 0) {
          const storedId = parseInt(localStorage.getItem("id"));

          const user = data.find((user) => user.id === storedId);

          if (user) {
            const { subscription } = user;
            const currentDate = new Date();
            const subscriptionDate = new Date(subscription);

            if (currentDate > subscriptionDate) {
              setShowNotification(true);
            }

            setSubscriptionEndDate(subscriptionDate);
          }
        }
      } catch (error) {
        console.error("Произошла ошибка:", error);
      }
    };

    fetchUserData();

    const interval = setInterval(fetchUserData, 24 * 60 * 60 * 1000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  useEffect(() => {
    setProvider((prevProvider) => ({
      ...prevProvider,
      Provider: localStorage.getItem("id"),
    }));
  }, []);

  useEffect(() => {
    const handleAddProductToProviders = () => {
      if (localStorage.getItem("idBulk")) {
        AddBulkProductToProviders(localStorage.getItem("idBulk"), provider)
          .then(() => {
            // Действия после успешного добавления продукта в провайдеров
          })
          .catch((error) => {
            console.error("Произошла ошибка при добавлении продукта:", error);
          });
      } else {
        AddProductionProductToProviders(
          localStorage.getItem("idProduct"),
          provider
        )
          .then(() => {
            // Действия после успешного добавления продукта в провайдеров
          })
          .catch((error) => {
            console.error("Произошла ошибка при добавлении продукта:", error);
          });
      }
    };

    handleAddProductToProviders();
  }, [provider]);

  const redirect = useNavigate();

  if (showNotification) {
    return <SubscribeNotification />;
  }

  return (
    <div className={styles.myTradesContainer}>
      <div className={styles.myTradesContent}>
        <h3>Сделки или визитка:</h3>
        <p className={styles.myTradesDescription}></p>

        <div className={styles.btn}>
          <Button
            type="primary"
            htmlType="button"
            className="btn-mytrades-exit"
            onClick={() => redirect("/selectDeals")}
          >
            Мои сделки
          </Button>
          <Button
            type="primary"
            htmlType="button"
            onClick={() => redirect("/businescard")}
            className="btn-mytrades-exit"
          >
            Моя визитка
          </Button>
        </div>
        <div className={styles.myTradesContent__btn}></div>
        <Button
          type="primary"
          className="btn-mytrades-exit"
          style={{ marginBottom: "15px" }}
        >
          <a href="https://t.me/+izs5JaEvHhdhZTBi">Канал для оптовых заказов</a>
        </Button>
        <Button
          type="primary"
          className="btn-mytrades-exit"
          style={{ marginBottom: "15px" }}
        >
          <a href="https://t.me/+8kAfZ2foxBY0ZTZi">Канал для заказов на пошив</a>
        </Button>
        <Button
          type="primary"
          className="btn-mytrades-exit"
          onClick={handleClickExit}
        >
          Выйти
        </Button>
      </div>
    </div>
  );
}

export default AuthPage;
